import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ImageGrid = makeShortcode("ImageGrid");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "connect"
    }}>{`Connect`}</h1>
    <p>{`Here are three strategies to help you create a healthy and strong relationship between you and your teenager:`}</p>
    <ImageGrid gridSize={4} list={[{
      src: '/images/m1/2-01.svg',
      alt: 'Show affection and encouragement',
      text: '1: Show affection and encouragement'
    }, {
      src: '/images/m1/2-02.svg',
      alt: 'Take the time to talk',
      text: '2: Take the time to talk'
    }, {
      src: '/images/m1/2-03.svg',
      alt: 'Talking through the tough stuff',
      text: '3: Talking through the tough stuff'
    }]} mdxType="ImageGrid" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      